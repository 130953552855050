//For Base URL and User Authentication
const BASE_URL = "https://api.automa.id/api";

const USER_URL = BASE_URL + "/user";
const LOGIN_URL = USER_URL + "/login";
const REGISTER_URL = USER_URL + "/register";
// const CHECK_URL = BASE_URL+"/auth";
const USER_LIST_URL = USER_URL + "/list"
const USER_STATUS_URL = USER_URL + "/status"
const CHANGE_PASSWORD_URL = BASE_URL + '/user/changepassword'

const COMPANY_BASE_URL = USER_URL + '/company'
const COMPANY_LIST_URL = COMPANY_BASE_URL + '/list'
const COMPANY_ADD_URL = COMPANY_BASE_URL + '/add'
const COMPANY_DELETE_URL = COMPANY_BASE_URL + '/delete'

const DRIVER_BASE_URL = BASE_URL + '/driver'
const DRIVER_LIST_URL = DRIVER_BASE_URL + '/list/driver'
const DRIVER_ADD_URL = DRIVER_BASE_URL + '/input/driver'
const DRIVER_EDIT_URL = DRIVER_BASE_URL + '/edit/driver'

const DRIVER_ASSISTANT_LIST_URL = DRIVER_BASE_URL + '/list/assistant';
const DRIVER_ASSISTANT_ADD_URL = DRIVER_BASE_URL + '/input/assistant';
const DRIVER_ASSISTANT_EDIT_URL = DRIVER_BASE_URL + '/edit/assistant';
const SECURITY_LIST_URL = DRIVER_BASE_URL + '/list/security';
const SECURITY_INPUT_URL = DRIVER_BASE_URL + '/input/security';

const FLEET_URL = BASE_URL + '/fleet';
const FLEET_LIST_URL = FLEET_URL + '/list'; 
const FLEET_INPUT_URL = FLEET_URL + '/input';
const FLEET_EDIT_URL = FLEET_URL + '/edit';

const SAVED_PLACES_URL = BASE_URL + '/transporter/place';
const SAVED_PLACES_ADD_URL = SAVED_PLACES_URL + '/input';
const SAVED_PLACES_LIST_URL = SAVED_PLACES_URL + '/list';
const SAVED_PLACES_APPROVAL_URL = SAVED_PLACES_URL + '/approve'
const SAVED_PLACES_EDIT_URL = SAVED_PLACES_URL + '/edit'

const MAIN_CUSTOMER_URL = BASE_URL + '/transporter/customer';
const MAIN_CUSTOMER_LIST_URL = MAIN_CUSTOMER_URL + '/list';
const MAIN_CUSTOMER_ADD_URL = MAIN_CUSTOMER_URL + '/add'
const MAIN_CUSTOMER_DELETE_URL = MAIN_CUSTOMER_URL + '/delete'
const MAIN_CUSTOMER_EDIT_URL = MAIN_CUSTOMER_URL + '/edit'

const OBD_URL = FLEET_URL + '/obd';
const OBD_TYPE_URL = OBD_URL + '/type';
const OBD_TYPE_LIST_URL = OBD_TYPE_URL + '/list'
const OBD_BINDING_URL = OBD_URL + '/binding'
const OBD_UNBINDING_URL = OBD_URL + '/unbinding'
const OBD_HISTORY_LIST_URL = OBD_URL + '/history/list'
const OBD_HISTORY_LIST_CHECK_URL = OBD_URL + '/history/check'

const DELIVERY_BASE_URL = BASE_URL + '/delivery';
const DELIVERY_APPROVE_URL = DELIVERY_BASE_URL + '/approve';
const DELIVERY_ORDERS_ADD_URL = DELIVERY_BASE_URL + '/input/order';
const DELIVERY_ORDERS_LIST_URL = DELIVERY_BASE_URL + '/list';
const WORK_ORDERS_ADD_URL = DELIVERY_BASE_URL + '/work/input/order';
const WORK_ORDERS_DRAFT_ADD_URL = DELIVERY_BASE_URL + '/work/draft/order';
const WORK_ORDERS_DRAFT_CHECK_URL = DELIVERY_BASE_URL + '/work/draft/check';
const WORK_ORDERS_DELETE_URL = DELIVERY_BASE_URL + '/work/draft/delete';
const WORK_ORDERS_EDIT_URL = DELIVERY_BASE_URL + '/work/edit/order';
const WORK_ORDERS_LIST_URL = DELIVERY_BASE_URL + '/work/list/v2';
const WORK_ORDERS_DRAFT_CONFIRM_URL = DELIVERY_BASE_URL + '/work/draft/confrim';
const WORK_ORDERS_DRAFT_EDIT_URL = DELIVERY_BASE_URL + '/work/draft/edit';
const WORK_ORDERS_DRAFT_TRIPL_ADD_URL = DELIVERY_BASE_URL + '/work/draft/order/request';
const DELIVERY_ORDER_ROUTE_SENT_PATH_DRIVER_URL = DELIVERY_BASE_URL + '/work/list/path/send';
const DELIVERY_STATUS_LIST_URL = DELIVERY_BASE_URL + '/status/list'
const WORK_ORDER_REVIEW_URL = DELIVERY_BASE_URL + '/work/list/status'
const WORK_ORDER_SECURITY_APPROVAL_STATUS_RESET_URL = DELIVERY_BASE_URL + '/work/reset/approval'

// 3PL related
const TRIPL_WO_REQ_LIST_URL = DELIVERY_BASE_URL + '/work/request/list'
const TRIPL_WO_REQ_BID_URL = DELIVERY_BASE_URL + '/work/request/bid'
const TRIPL_WO_REQ_INCOMING_BID_LIST_URL = DELIVERY_BASE_URL + '/work/request/incoming/bid/list'
const TRIPL_WO_REQ_APPROVED_REQUEST_LIST_URL = DELIVERY_BASE_URL + '/work/request/approve/list'
const TRIPL_WO_REQ_LIST_RESULT_URL = DELIVERY_BASE_URL + '/work/request/list/all'
const TRIPL_WO_REQ_ADD_URL = DELIVERY_BASE_URL + '/work/request/input/order'
const TRIPL_WO_APPROVE_BID_URL = DELIVERY_BASE_URL + '/work/request/list/bid/approve'
const TRIPL_WO_DECLINE_BID_URL = DELIVERY_BASE_URL + '/work/request/list/bid/decline'
const TRIPL_WO_BINDING_WO_DO_URL = DELIVERY_BASE_URL + '/work/input/order/request'
const TRIPL_STATUS_TO_FINISH_AND_PAID = DELIVERY_BASE_URL + '/work/request/list/bid/confrim/paid'

const CAR_URL = BASE_URL + '/car';
const CAR_LIST_BRAND_URL = CAR_URL + '/list/brand';
const CAR_LIST_TYPE_URL = CAR_URL + '/list/type';
const CAR_LIST_GENERAL_URL = CAR_URL + '/list/general';
const CAR_LIST_FUEL_TYPE_URL = CAR_URL + '/list/fuel';

const TRANSPORTER_URL = BASE_URL + '/transporter'
const TRANSPORTER_DELETE_URL = TRANSPORTER_URL + '/delete'
const REPORT_BASE_URL = TRANSPORTER_URL + '/report'
const DELIVERY_AUDIT_URL = REPORT_BASE_URL + '/audit'
const CAR_DETAILS_TABLE_URL = REPORT_BASE_URL + '/log'
const DELIVERY_CHECK_IN_LOG_URL = REPORT_BASE_URL + '/check/in/log'
const FLEET_REPORT_URL = REPORT_BASE_URL + '/beetwen';
const FLEET_FUEL_REPORT_URL = REPORT_BASE_URL + '/fuel'
const REPORT_DAILY_SUM = REPORT_BASE_URL + '/daily'

const LIVE_MONITORING_URL = REPORT_BASE_URL + '/live'

const SETTING_CHANGE_URL = USER_URL + '/settingchange'
const SETTING_LIST_URL = USER_URL + '/settinglist'

const COMPANY_SETTING_LIST_URL = USER_URL + '/company/list'
const COMPANY_EDIT_URL = USER_URL + '/company/edit'

const MAINTENANCE_BASE_URL = FLEET_URL + '/service'
const FLEET_MAINTENANCE_LIST_URL = MAINTENANCE_BASE_URL + '/list'
const FLEET_MAINTENANCE_INPUT_URL = MAINTENANCE_BASE_URL + '/input'
const FLEET_MAINTENANCE_EDIT_URL = MAINTENANCE_BASE_URL + '/edit'

// Fuel Management
const FUEL_URL = FLEET_URL + '/fuel'
const FUEL_MANAGEMENT_LIST = FUEL_URL + '/track/list'
const FUEL_MANAGEMENT_ADD = FUEL_URL + '/track/add'
const FUEL_MANAGEMENT_APPROVAL = FUEL_URL + '/track/approval'

const DASHBOARD_URL = BASE_URL + '/dashboard'
const TRANSPORTER_DASHBOARD_URL = DASHBOARD_URL + '/transporter'
const DASHBOARD_TRANSPORTER_COUNT_URL = TRANSPORTER_DASHBOARD_URL + '/count'
const TRANSPORTER_FLEET_NOTIFICATION_DASHBOARD_URL = TRANSPORTER_DASHBOARD_URL + '/reminder/fleet'
const TRANSPORTER_SIM_NOTIFICATION_DASHBOARD_URL = TRANSPORTER_DASHBOARD_URL + '/reminder/sim'
const TRANSPORTER_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD_URL = TRANSPORTER_DASHBOARD_URL + '/reminder/service'

const UPLOAD_IMAGE_FOR_EDIT = BASE_URL + '/iot/logging/upload'
const TRIPL_STATE_TRANSFERED_ADD_URL = TRANSPORTER_DASHBOARD_URL + '/state/transfer/add' 
const TRIPL_STATE_TRANSFERED_DELETE_URL = TRANSPORTER_DASHBOARD_URL + '/state/transfer/delete' 
const TRIPL_STATE_TRANSFERED_LIST_URL = TRANSPORTER_DASHBOARD_URL + '/state/transfer/list' 

// Links - OBD
const OBD_LIST_URL = OBD_URL + '/list'
const OBD_ADD_URL = OBD_URL + '/add'
const OBD_EDIT_URL = OBD_URL + '/edit'
const OBD_DELETE_URL = OBD_URL + '/delete'

// Links - Car Brand
const CAR_BRAND_LIST_URL = CAR_URL + '/list/brand'
const CAR_BRAND_ADD_URL = CAR_URL + '/input/brand'
const CAR_TYPE_LIST_URL = CAR_URL + '/list/type'
const CAR_TYPE_ADD_URL = CAR_URL + '/input/type'

const OBDHW_LIST_URL = 'https://transporter.awakavr.id/gateway/transporter/api/'
const OBD_HW_LIST_INFO_URL = 'https://transporter.awakavr.id/transporter/devman/api/index.php'

const BROADCAST_WHATSAPP_COMPANY_URL = USER_URL + '/broadcast'
const BROADCAST_WHATSAPP_OBD_URL = BROADCAST_WHATSAPP_COMPANY_URL + '/obd'

const INVOICE_LIST_URL = USER_URL + '/company/invoice'

const TRIPL_BID_WINNER_TRACKER_URL = DELIVERY_BASE_URL + '/work/request/history/list/path'
const TRIPL_HISTORY_LIST_PATH_URL = DELIVERY_BASE_URL + '/work/request/history/list'

//Public Places
const PLACE_LIST_URL =  BASE_URL + '/transporter/place/list'
const PUBLIC_FACILITY_LIST_URL =  BASE_URL + '/transporter/public/place/list'
const PUBLIC_FACILITY_ADD_URL =  BASE_URL + '/transporter/public/place/input'
const PUBLIC_FACILITY_ADD_BULK_URL =  BASE_URL + '/transporter/public/place/input/bulk'
const PUBLIC_FACILITY_DELETE_URL =  BASE_URL + '/transporter/public/place/delete'
const PUBLIC_FACILITY_APPROVAL_URL =  BASE_URL + '/transporter/public/place/approve'
const PUBLIC_FACILITY_DISAPPROVE_URL = BASE_URL + '/transporter/public/place/disapprove'

const PUBLIC_FACILITY_TYPE_LIST_URL =  BASE_URL + '/transporter/public/place/type/list'
const PUBLIC_FACILITY_TYPE_ADD_URL =  BASE_URL + '/transporter/public/place/type/input'
// const PUBLIC_FACILITY_TYPE_DELETE_URL =  BASE_URL + '/transporter/public/place/delete'

const USER_PAYMENT_REQUEST_LIST = USER_URL +"/payment/internal/saldo/invoice/approve/list";
const USER_PAYMENT_APPROVE = USER_URL +"/payment/internal/saldo/invoice/approve";
const USER_PAYMENT_DECLINE = USER_URL +"/payment/internal/saldo/invoice/decline";


const BANK_BOOK_LIST = USER_URL + "/transactional/fva/list/automa";
const WITHDRAWAL_LIST = USER_URL + "/withdrawal/list";
const WITHDRAWAL_APPROVAL = USER_URL + "/withdrawal/approval";
const WITHDRAWAL_DECLINE = USER_URL + "/withdrawal/decline";

export {
    LOGIN_URL,
    REGISTER_URL,
    CHANGE_PASSWORD_URL,
    USER_LIST_URL,
    USER_STATUS_URL,
    CAR_DETAILS_TABLE_URL,
    
    //Drivers
    DRIVER_BASE_URL,
    DRIVER_LIST_URL,
    DRIVER_ADD_URL,
    DRIVER_EDIT_URL,

    DRIVER_ASSISTANT_LIST_URL,
    DRIVER_ASSISTANT_ADD_URL,
    DRIVER_ASSISTANT_EDIT_URL,

    SECURITY_LIST_URL,
    SECURITY_INPUT_URL,

    FLEET_LIST_URL,
    FLEET_INPUT_URL,
    FLEET_EDIT_URL,

    //Saved Places
    SAVED_PLACES_ADD_URL,
    SAVED_PLACES_LIST_URL,
    SAVED_PLACES_APPROVAL_URL,
    SAVED_PLACES_EDIT_URL,

    //Main Customer
    MAIN_CUSTOMER_LIST_URL,
    MAIN_CUSTOMER_ADD_URL,
    MAIN_CUSTOMER_EDIT_URL,
    MAIN_CUSTOMER_DELETE_URL,

    // Delivery
    DELIVERY_BASE_URL,
    DELIVERY_ORDERS_ADD_URL,
    DELIVERY_ORDERS_LIST_URL,
    DELIVERY_APPROVE_URL,
    DELIVERY_ORDER_ROUTE_SENT_PATH_DRIVER_URL,
    WORK_ORDERS_ADD_URL,
    WORK_ORDERS_LIST_URL,
    WORK_ORDERS_EDIT_URL,
    WORK_ORDERS_DELETE_URL,
    WORK_ORDERS_DRAFT_ADD_URL,
    WORK_ORDERS_DRAFT_TRIPL_ADD_URL,
    WORK_ORDERS_DRAFT_CHECK_URL,
    WORK_ORDERS_DRAFT_CONFIRM_URL,
    WORK_ORDERS_DRAFT_EDIT_URL,
    WORK_ORDER_REVIEW_URL,
    DELIVERY_STATUS_LIST_URL,
    WORK_ORDER_SECURITY_APPROVAL_STATUS_RESET_URL,

    // 3PL
    TRIPL_WO_REQ_LIST_URL,
    TRIPL_WO_REQ_LIST_RESULT_URL,
    TRIPL_WO_REQ_ADD_URL,
    TRIPL_WO_REQ_BID_URL,
    TRIPL_WO_REQ_INCOMING_BID_LIST_URL,
    TRIPL_WO_APPROVE_BID_URL,
    TRIPL_WO_DECLINE_BID_URL,
    TRIPL_WO_REQ_APPROVED_REQUEST_LIST_URL,
    TRIPL_WO_BINDING_WO_DO_URL,
    TRIPL_STATUS_TO_FINISH_AND_PAID,
    TRIPL_STATE_TRANSFERED_LIST_URL,
    TRIPL_STATE_TRANSFERED_ADD_URL,
    TRIPL_STATE_TRANSFERED_DELETE_URL,

    //OBD
    OBD_TYPE_LIST_URL,
    OBD_BINDING_URL,
    OBD_UNBINDING_URL,
    OBD_HISTORY_LIST_URL,
    OBD_HISTORY_LIST_CHECK_URL,

    //Car
    CAR_LIST_BRAND_URL,
    CAR_LIST_TYPE_URL,
    CAR_LIST_GENERAL_URL,
    CAR_LIST_FUEL_TYPE_URL,

    DELIVERY_CHECK_IN_LOG_URL,
    LIVE_MONITORING_URL,

    TRANSPORTER_DELETE_URL,

    FLEET_REPORT_URL,
    DELIVERY_AUDIT_URL,
    REPORT_DAILY_SUM,
    FLEET_FUEL_REPORT_URL,

    //Maintenance
    FLEET_MAINTENANCE_LIST_URL,
    FLEET_MAINTENANCE_INPUT_URL,
    FLEET_MAINTENANCE_EDIT_URL,

    // Fuel Management
    FUEL_MANAGEMENT_ADD,
    FUEL_MANAGEMENT_LIST,
    FUEL_MANAGEMENT_APPROVAL,

    //Settings
    SETTING_CHANGE_URL,
    SETTING_LIST_URL,
    COMPANY_SETTING_LIST_URL,
    

    //TRANSPORTER DASHBOARD
    DASHBOARD_TRANSPORTER_COUNT_URL,
    TRANSPORTER_SIM_NOTIFICATION_DASHBOARD_URL,
    TRANSPORTER_FLEET_NOTIFICATION_DASHBOARD_URL,
    TRANSPORTER_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD_URL,
    
    //EDITING IMAGES
    UPLOAD_IMAGE_FOR_EDIT,

    // Company
    COMPANY_LIST_URL,
    COMPANY_ADD_URL,
    COMPANY_DELETE_URL,
    COMPANY_EDIT_URL,

    // Car related API
    CAR_BRAND_ADD_URL,
    CAR_BRAND_LIST_URL,
    CAR_TYPE_LIST_URL,
    CAR_TYPE_ADD_URL,

    // OBDHW - DEV
    OBDHW_LIST_URL,
    OBD_HW_LIST_INFO_URL,

    // OBD
    OBD_LIST_URL,
    OBD_ADD_URL,
    OBD_EDIT_URL,
    OBD_DELETE_URL,

    // Broadcasting Whatsapp
    BROADCAST_WHATSAPP_COMPANY_URL,
    BROADCAST_WHATSAPP_OBD_URL,

    // Invoice
    INVOICE_LIST_URL,

    //Review
    TRIPL_BID_WINNER_TRACKER_URL,
    TRIPL_HISTORY_LIST_PATH_URL,

    //Public Places
    //Gas Staions
    PLACE_LIST_URL,
    PUBLIC_FACILITY_LIST_URL,
    PUBLIC_FACILITY_ADD_URL,
    PUBLIC_FACILITY_ADD_BULK_URL,
    PUBLIC_FACILITY_DELETE_URL,
    PUBLIC_FACILITY_APPROVAL_URL,
    PUBLIC_FACILITY_DISAPPROVE_URL,

    PUBLIC_FACILITY_TYPE_LIST_URL,
    PUBLIC_FACILITY_TYPE_ADD_URL,

    USER_PAYMENT_REQUEST_LIST,
    USER_PAYMENT_APPROVE,
    USER_PAYMENT_DECLINE,
    BANK_BOOK_LIST,
    WITHDRAWAL_LIST,
    WITHDRAWAL_APPROVAL,
    WITHDRAWAL_DECLINE
}