import { FETCH_COMPANIES, RECEIVE_COMPANIES, FAILED_COMPANIES } from '../actions/companies.actions'

const defaultCompaniesTableState = {
    companiesData: [],
    totalCompanies: 0, 
    success: false,
    selectCompanies: []
}

export function companies(state = defaultCompaniesTableState, action){
    switch (action.type) {
        case FETCH_COMPANIES:
            return ({companiesData: [], totalCompanies: 0, selectCompanies: [], success: false, inProgress: true})
        case RECEIVE_COMPANIES:
            var _totalCompanies = action.payload.result.length
            var list = []
            var data = action.payload.result
            if(_totalCompanies > 0) {
                data.forEach((p, index) => {
                    var item = {
                        'label': data[index].company + ' - ' + data[index].telp,
                        'value': data[index].id
                    }
                    list.push(item)
                });
            }
            return Object.assign({}, state, 
                {
                    ...state,
                    companiesData: action.payload.result, 
                    totalCompanies: _totalCompanies, 
                    selectCompanies: list,
                    success: true, 
                    inProgress: false
                })
        case FAILED_COMPANIES:
            return ({companiesData: [], totalCompanies: 0, selectCompanies: [], success: false, inProgress: false})
        default:
            return state
    }
}