import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './redux/reducers/index.jsx';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist"


const persistConfig = {
    key: "root",
    storage,
    whitelist: ['authStore']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = composeWithDevTools(applyMiddleware(promise.default, thunk));

export default createStore(persistedReducer, middleware);