import { FETCH_OBD_INFO_LIST, RECEIVE_OBD_INFO_LIST, FAILED_OBD_INFO_LIST } from '../../actions/transporter/obdinfo.act'

const defaultObdInfoListState = {
    obdInfoListData: [],
    totalObdInfo: 0, 
    success: true,
    inProgress: false,
    selectObd: []
}

export function ObdInfo(state = defaultObdInfoListState, action){
    switch (action.type) {
        case FETCH_OBD_INFO_LIST:
            return ({obdInfoListData: [], totalObdInfo: 0, selectObd: [], success: false, inProgress: true})
        case RECEIVE_OBD_INFO_LIST:
            var _totalObdInfo = action.payload.result.length
            // var data = action.payload.result
            var list = []
            // if(data.length > 0) {
            //     data.forEach((p, index) => {
            //         var item = {
            //             'label': data[index].obd_code + ' - ' + data[index].company,
            //             'value': data[index].obd_code
            //         }
            //         list.push(item)
            //     });
            // }
            return Object.assign({}, state, 
                {
                    ...state,
                    obdInfoListData: action.payload.result, 
                    totalObdInfo: _totalObdInfo, 
                    selectObd: list,
                    success: true, 
                    inProgress: false
                })
        case FAILED_OBD_INFO_LIST:
            return ({obdInfoListData: [], totalObdInfo: 0, selectObd: [], success: false, inProgress: false})
        default:
            return state
    }
}