import axios from "axios";
import {CAR_BRAND_LIST_URL} from '../../config/links.js'
import {toast} from 'react-toastify'

export const FETCH_CAR_BRAND_LIST = 'FETCH_CAR_BRAND_LIST'
export const RECEIVE_CAR_BRAND_LIST = 'RECEIVE_CAR_BRAND_LIST'
export const FAILED_CAR_BRAND_LIST = 'FAILED_CAR_BRAND_LIST'

export function fetchCarBrand(){
    var loginCreds = JSON.parse(window.atob(localStorage.AutomaMtklt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_CAR_BRAND_LIST});
        axios({
            method: 'post',
            url: CAR_BRAND_LIST_URL,
            headers: {
                Authorization: auth
            },
            // data: {
            //     dev: 'T02',
            //     // date: 
            //     // For Parameters
            // }
        })
        .then((response) => {
            // console.log(response.data)
            dispatch( {
                type: RECEIVE_CAR_BRAND_LIST,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_CAR_BRAND_LIST
                    })
                    return toast.error('Unathorized access!');
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_CAR_BRAND_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_CAR_BRAND_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_CAR_BRAND_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_CAR_BRAND_LIST
                })
                return toast.error("Something went wrong!... Please try again later.");
            } else {
                dispatch({
                    type: FAILED_CAR_BRAND_LIST
                })
                return toast.error("Something went wrong!... Please try again later.");
            }

        })
    }
}