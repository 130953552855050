import { FETCH_OBD_LIST, RECEIVE_OBD_LIST, FAILED_OBD_LIST } from '../../actions/transporter/obd.actions'

const defaultObdListState = {
    obdListData: [],
    totalObd: 0, 
    success: true,
    inProgress: false,
    selectObd: []
}

export function Obd(state = defaultObdListState, action){
    switch (action.type) {
        case FETCH_OBD_LIST:
            return ({obdListData: [], totalObd: 0, selectObd: [], success: false, inProgress: true})
        case RECEIVE_OBD_LIST:
            var _totalObd = action.payload.result.length
            var data = action.payload.result
            var list = []
            if(data.length > 0) {
                data.forEach((p, index) => {
                    var item = {
                        'label': data[index].obd_code + ' - ' + data[index].company,
                        'value': data[index].obd_code
                    }
                    list.push(item)
                });
            }
            return Object.assign({}, state, 
                {
                    ...state,
                    obdListData: action.payload.result, 
                    totalObd: _totalObd, 
                    selectObd: list,
                    success: true, 
                    inProgress: false
                })
        case FAILED_OBD_LIST:
            return ({obdListData: [], totalObd: 0, selectObd: [], success: false, inProgress: false})
        default:
            return state
    }
}