import { FETCH_CAR_TYPE_LIST, RECEIVE_CAR_TYPE_LIST, FAILED_CAR_TYPE_LIST } from '../../actions/transporter/cartype.actions'

const defaultCarTypeListState = {
    carTypeListData: [],
    selectCarType: [],
    totalCarType: 0, 
    success: false,
}

export function carType(state = defaultCarTypeListState, action){
    switch (action.type) {
        case FETCH_CAR_TYPE_LIST:
            return ({carTypeListData: [], totalCarType: 0, selectCarType: [], success: false, inProgress: true})
        case RECEIVE_CAR_TYPE_LIST:
            var _totalCarType = action.payload.result.length
            var data = action.payload.result;
            var list = []
            if(data !== []) {
                data.forEach((p, index) => {
                    var item = {
                        'label': data[index].name,
                        'value': data[index].id
                    }
                    list.push(item)
                });
            }
            return Object.assign({}, state, 
                {
                    ...state,
                    carTypeListData: action.payload.result, 
                    totalCarType: _totalCarType, 
                    selectCarType: list,
                    success: true, 
                    inProgress: false
                })
        case FAILED_CAR_TYPE_LIST:
            return ({carTypeListData: [], totalCarType: 0, selectCarType: [], success: false, inProgress: false})
        default:
            return state
    }
}