import { FETCH_CAR_BRAND_LIST, RECEIVE_CAR_BRAND_LIST, FAILED_CAR_BRAND_LIST } from '../../actions/transporter/carbrands.actions'

const defaultCarBrandListState = {
    carBrandListData: [],
    selectCarBrand: [],
    totalCarBrand: 0, 
    success: false,
}

export function carBrands(state = defaultCarBrandListState, action){
    switch (action.type) {
        case FETCH_CAR_BRAND_LIST:
            return ({carBrandListData: [], totalCarBrand: 0, selectCarBrand: [], success: false, inProgress: true})
        case RECEIVE_CAR_BRAND_LIST:
            var _totalBrand = action.payload.result.length
            var data = action.payload.result
            var list = []
            if(data !== []) {
                data.forEach((p, index) => {
                    var item = {
                        'label': data[index].name,
                        'value': data[index].id
                    }
                    list.push(item)
                });
            }
            return Object.assign({}, state, 
                {
                    ...state,
                    carBrandListData: action.payload.result, 
                    totalCarBrand: _totalBrand, 
                    selectCarBrand: list,
                    success: true, 
                    inProgress: false
                })
        case FAILED_CAR_BRAND_LIST:
            return ({carBrandListData: [], totalCarBrand: 0, selectCarBrand: [], success: false, inProgress: false})
        default:
            return state
    }
}