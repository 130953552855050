import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const AuthCheck = ({ ...props }) => {
  const [timer, setTimer] = useState(new Date().getTime());

  useEffect(() => {
    setInterval(() => {
      setTimer((prev) => prev + 1000);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!!props.authData && !!props.authData.result) {
      // const localTime = (new Date().getTimezoneOffset() / -60) * 60 * 60 * 1000;
      // const expTime = props.authData.result.expires * 1000 + localTime;
      // const expTime = props.authData.result.expires * 1000;
      const expTime = props.authData.result.expires;
      // console.log(expTime, timer, expTime - timer);
      if (expTime - timer <= 0) {
        window.location.href = "/#/logout";
      }
    }
  }, [props.authData, timer]);
  return <>{props.children}</>;
};

const mapStateToProps = (state) => {
  return {
    authData: state.authStore.authData,
  };
};

export default connect(mapStateToProps, {})(AuthCheck);
