import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

import { Provider } from "react-redux";
import store from './store.js';

import { AbilityContext } from './casl/Can'
import ability from './casl/Ability.jsx'
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import AuthCheck from './views/AuthCheck/AuthCheck';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
// const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/Main/Login/Login'));
const Logout = React.lazy(() => import('./views/Main/Logout/Logout'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


const persistor = persistStore(store);
class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <AbilityContext.Provider value={ability}>
              <AuthCheck>
          <HashRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  
                  {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props}/>} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
              </React.Suspense>
          </HashRouter>
              </AuthCheck>
        </AbilityContext.Provider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;

