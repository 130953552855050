import { FETCH_UNIT_LIST, RECEIVE_UNIT_LIST, FAILED_UNIT_LIST } from '../../actions/stocker/unit.actions'

const defaultUnitState = {
    unitTotal: 0,
    unitData: [],
    success: true,  
    inProgress: false
}

export function Units(state = defaultUnitState, action){
    switch (action.type) {
        case FETCH_UNIT_LIST:
            return ({unitTotal: 0, unitData: [], success: true, inProgress: true})
        case RECEIVE_UNIT_LIST:
            return Object.assign({}, state, {unitTotal: action.payload.result.length,
                                                unitData: action.payload.result, 
                                                success: true, inProgress: false})
        case FAILED_UNIT_LIST:
            return ({unitTotal: 0, unitData: [], success: false, inProgress: false})
        default:
            return state
    }
}