import {FETCH_STATE_TRANSFER_LIST,
    RECEIVE_STATE_TRANSFER_LIST,
    FAILED_STATE_TRANSFER_LIST} 
    from '../../actions/transporter/statetransfer.actions'


const defaultstateTransferState = {
    totalstateTransfer:0,
    stateTransferData: [],
    success: true,  
    inProgress: false
}

export function stateTransfer(state = defaultstateTransferState, action){
    switch (action.type) {
        case FETCH_STATE_TRANSFER_LIST:
            return ({stateTransferData: [], success: true, inProgress: true, totalstateTransfer:0})
        case RECEIVE_STATE_TRANSFER_LIST:
            return Object.assign({}, state, {stateTransferData: action.payload, 
                                                totalstateTransfer: action.payload.result.length,
                                                success: true, inProgress: false})
        case FAILED_STATE_TRANSFER_LIST:
            return ({stateTransferData: [], success: false, inProgress: false, totalstateTransfer:0,})
        default:
            return state
    }
}