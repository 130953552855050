import { combineReducers } from 'redux';
import { fetchAuth } from './auth.red.js';

//Transporter
import { changeState } from './changeState.jsx'
import { companies } from './companies.red.js';
import { carBrands } from './transporter/carbrands.reducers.js';
import { carType } from './transporter/cartype.reducer.js';
import { Obd } from './transporter/obd.red.js';
import { ObdInfo } from './transporter/obdinfo.red.js';
import { triPlInvoice } from './transporter/triplinvoice.red.js';
import { triPLWinnerTracker, triPLHistoryPath } from './transporter/triPLWinnerTracker.red'
import { stateTransfer } from './transporter/stateTransfer.red'

//Transporter Public Places
import { publicFacility } from './transporter/publicfacility.red'
import { publicFacilityType } from './transporter/publicfacilitytype.red'

//Stocker
import { Units } from './stocker/unit.reducers'

const rootReducer = combineReducers({
    changeState: changeState,

    authStore: fetchAuth,
    companyStore: companies,
    obdStore: Obd,
    carBrandStore: carBrands,
    carTypeStore: carType,
    obdInfoStore: ObdInfo,
    triPlInvoiceStore: triPlInvoice,
    triPLWinnerTrackerStore : triPLWinnerTracker,
    triPLHistoryPathStore : triPLHistoryPath,
    stateTransferStore: stateTransfer,

    //Transporter Public Places
    publicFacilityStore: publicFacility,
    publicFacilityTypeStore: publicFacilityType,
    
    //Stocker
    unitsStore: Units

})

export default rootReducer;