import { FETCH_TRIPL_WINNER_TRACKER_LIST,  
    RECEIVE_TRIPL_WINNER_TRACKER_LIST,
    FAILED_TRIPL_WINNER_TRACKER_LIST ,
    FETCH_TRIPL_HISTORY_PATH_LIST,
    RECEIVE_TRIPL_HISTORY_PATH_LIST,
    FAILED_TRIPL_HISTORY_PATH_LIST} 
    from '../../actions/transporter/triPLWinnerTracker.action'

const nullIdGroupAsAutomaWide = function(triPlRequest){
triPlRequest.map(request =>{
    if(request.id_group === null)
        request.group_name = "Automa Wide"
    return request
})
return triPlRequest
}

const defaultTriPLWinnerTrackerListState = {
triPLWinnerTrackerListData: [],
success: true,  
inProgress: false
}

export function triPLWinnerTracker(state = defaultTriPLWinnerTrackerListState, action){
switch (action.type) {
    case FETCH_TRIPL_WINNER_TRACKER_LIST:
        return ({triPLWinnerTrackerListData: [], success: true, inProgress: true})
    case RECEIVE_TRIPL_WINNER_TRACKER_LIST:
        return Object.assign({}, state, {triPLWinnerTrackerListData: nullIdGroupAsAutomaWide(action.payload.result), 
                                            success: true, inProgress: false})
    case FAILED_TRIPL_WINNER_TRACKER_LIST:
        return ({triPLWinnerTrackerListData: [], success: false, inProgress: false})
    default:
        return state
}
}

const defaultTriPLHistoryPathListState = {
    totalTriPLHistoryPathList:0,
    triPLHistoryPathListData: [],
    success: true,  
    inProgress: false
}

export function triPLHistoryPath(state = defaultTriPLHistoryPathListState, action){
switch (action.type) {
    case FETCH_TRIPL_HISTORY_PATH_LIST:
        return ({triPLHistoryPathListData: [], success: true, inProgress: true, totalTriPLHistoryPathList:0})
    case RECEIVE_TRIPL_HISTORY_PATH_LIST:
        return Object.assign({}, state, {triPLHistoryPathListData: action.payload, 
                                            totalTriPLHistoryPathList: action.payload.result.length,
                                            success: true, inProgress: false})
    case FAILED_TRIPL_HISTORY_PATH_LIST:
        return ({triPLHistoryPathListData: [], success: false, inProgress: false, totalTriPLHistoryPathList:0,})
    default:
        return state
}
}