import axios from "axios";
import * as API_LINKS from '../../config/links.js'
import {toast} from 'react-toastify'
import handleErrorResponseCode from '../../../helpers/error-response-code-handler'

export const FETCH_PUBLIC_FACILITY_LIST = 'FETCH_PUBLIC_FACILITY_LIST'
export const RECEIVE_PUBLIC_FACILITY_LIST = 'RECEIVE_PUBLIC_FACILITY_LIST'
export const FAILED_PUBLIC_FACILITY_LIST = 'FAILED_PUBLIC_FACILITY_LIST'

export function fetchPublicFacility(){
    var loginCreds = JSON.parse(window.atob(localStorage.AutomaMtklt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({type: FETCH_PUBLIC_FACILITY_LIST});
        axios({
            method: 'post',
            url: API_LINKS.PLACE_LIST_URL,
            headers: {
                Authorization: auth
            },
            data:{
                public_place: 1
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PUBLIC_FACILITY_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            let errorResponseCode = error.response
            if (errorResponseCode) {
                dispatch({
                    type: FAILED_PUBLIC_FACILITY_LIST
                })
                toast.error(
                    handleErrorResponseCode(errorResponseCode)
                )                 
            }
        })
    }
}

export const APPROVE_PUBLIC_FACILITY= 'APPROVE_PUBLIC_FACILITY'

export function approvePublicFacility(_id_gas_station){
    var loginCreds = JSON.parse(window.atob(localStorage.AutomaMtklt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({
            type: APPROVE_PUBLIC_FACILITY,
            payload: {id: _id_gas_station}
        });
        axios({
            method: 'post',
            url: API_LINKS.PUBLIC_FACILITY_APPROVAL_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id: _id_gas_station
            }
        })
        .then((response) => {
            if(response.data.status === "success"){
                toast.success("Status changed successfully!", 
                    {
                        autoClose: 1000
                    }
                )
            }
        })
        .catch(function(error){
            let errorResponseCode = error.response
            if (errorResponseCode) {
                dispatch({
                    type: FAILED_PUBLIC_FACILITY_LIST
                })
                toast.error(
                    handleErrorResponseCode(errorResponseCode)
                )                 
            }
        })
    }
}

export const DISAPPROVE_PUBLIC_FACILITY= 'DISAPPROVE_PUBLIC_FACILITY'

export function disApprovePublicFacility(_id_gas_station){
    var loginCreds = JSON.parse(window.atob(localStorage.AutomaMtklt));
    var auth = loginCreds.token;

    return (dispatch, getState) => {
        dispatch({
            type: DISAPPROVE_PUBLIC_FACILITY,
            payload: {id: _id_gas_station}
        });
        axios({
            method: 'post',
            url: API_LINKS.PUBLIC_FACILITY_DISAPPROVE_URL,
            headers: {
                Authorization: auth
            },
            data:{
                id: _id_gas_station
            }
        })
        .then((response) => {
            if(response.data.status === "success"){
                toast.success("Status changed successfully!", 
                    {
                        autoClose: 1000
                    }
                )
            }
        })
        .catch(function(error){
            let errorResponseCode = error.response
            if (errorResponseCode) {
                dispatch({
                    type: FAILED_PUBLIC_FACILITY_LIST
                })
                toast.error(
                    handleErrorResponseCode(errorResponseCode)
                )                 
            }
        })
    }
}