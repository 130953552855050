import { FETCH_TRIPL_INVOICE_LIST, RECEIVE_TRIPL_INVOICE_LIST, FAILED_TRIPL_INVOICE_LIST } from '../../actions/transporter/triplinvoice.act'

const defaultTriPlInvoiceListState = {
    triPlInvoiceListData: [],
    selectTriPlInvoice: [],
    totalTriPlInvoice: 0, 
    success: false,
}

export function triPlInvoice(state = defaultTriPlInvoiceListState, action){
    switch (action.type) {
        case FETCH_TRIPL_INVOICE_LIST:
            return ({triPlInvoiceListData: [], totalTriPlInvoice: 0, selectTriPlInvoice: [], success: false, inProgress: true})
        case RECEIVE_TRIPL_INVOICE_LIST:
            var _totalTriPlInvoice = action.payload.result.length
            var data = action.payload.result;
            var list = []
            if(data !== []) {
                var item = {
                    'label': '- All Companies -',
                    'value': null
                }
                list.push(item)

                data.forEach((p, index) => {
                    var item = {
                        'label': data[index].company + ' - ' + data[index].invoice_no_xendit,
                        'value': data[index].id
                    }
                    list.push(item)
                });
            }
            return Object.assign({}, state, 
                {
                    ...state,
                    triPlInvoiceListData: action.payload.result, 
                    totalTriPlInvoice: _totalTriPlInvoice, 
                    selectTriPlInvoice: list,
                    success: true, 
                    inProgress: false
                })
        case FAILED_TRIPL_INVOICE_LIST:
            return ({triPlInvoiceListData: [], totalTriPlInvoice: 0, selectTriPlInvoice: [], success: false, inProgress: false})
        default:
            return state
    }
}