import { 
        FETCH_PUBLIC_FACILITY_LIST,  
        RECEIVE_PUBLIC_FACILITY_LIST,
        FAILED_PUBLIC_FACILITY_LIST ,
        APPROVE_PUBLIC_FACILITY,
        DISAPPROVE_PUBLIC_FACILITY
    } 
    from '../../actions/transporter/publicfacility.action'

const defaultPublicFacilityState = {
    totalPublicFacility:0,
    publicFacilityData: [],
    success: true,  
    inProgress: false
}


export function publicFacility(state = defaultPublicFacilityState, action){
    switch (action.type) {
        case FETCH_PUBLIC_FACILITY_LIST:
            return ({publicFacilityData: [], success: true, inProgress: true, totalPublicFacility:0})
        case RECEIVE_PUBLIC_FACILITY_LIST:
            return Object.assign({}, state, {publicFacilityData: action.payload.result, 
                                                totalPublicFacility: action.payload.result.length,
                                                success: true, inProgress: false})
        case APPROVE_PUBLIC_FACILITY:
            state.publicFacilityData.map((data) => {
                const APPROVE_ID = 1
                if (data.id === action.payload.id) {
                    data.status =  APPROVE_ID
                }
                return data;
            });
           return Object.assign({}, state, {publicFacilityData: state.publicFacilityData, 
                totalPublicFacility: state.publicFacilityData.length,
                success: true, inProgress: false})
        case DISAPPROVE_PUBLIC_FACILITY:
            state.publicFacilityData.map((data) => {
                const DISAPPROVE_ID = 4
                if (data.id === action.payload.id) {
                    data.status =  DISAPPROVE_ID
                }
                return data;
            });
           return Object.assign({}, state, {publicFacilityData: state.publicFacilityData, 
                totalPublicFacility: state.publicFacilityData.length,
                success: true, inProgress: false})
        case FAILED_PUBLIC_FACILITY_LIST:
            return ({publicFacilityData: [], success: false, inProgress: false, totalPublicFacility:0,})
        default:
            return state
    }
}