const BASE_URL = "https://api.automa.id/api/stocker";

const UNIT_LIST_URL = BASE_URL + "/unit/list"
const UNIT_ADD_URL = BASE_URL + "/unit/input"
const UNIT_EDIT_URL = BASE_URL + "/unit/edit"
const UNIT_DELETE_URL = BASE_URL + "/unit/delete"

export {
    UNIT_LIST_URL,
    UNIT_ADD_URL,
    UNIT_EDIT_URL,
    UNIT_DELETE_URL
}